import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { TextFieldProps } from "@mui/material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import type { HTMLInputTypeAttribute } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "remix-validated-form";

import { useValidatedControlField } from "~/hooks/use-validated-control-field.ts";
import { useZodErrorWithParameters } from "~/utils/zod-validators.ts";

export type Props = TextFieldProps & {
	name: string;
	labelTranslationKey: string;
	isReadOnly?: boolean;
	hideErrorText?: boolean;
	capitalize?: boolean;
	isPassword?: boolean;
	warningText?: string;
};

export const ValidatedTextField = ({
	name,
	labelTranslationKey,
	isReadOnly,
	hideErrorText,
	type,
	capitalize,
	isPassword,
	warningText,
	...props
}: Props) => {
	const { error, getInputProps } = useField(name);
	const [value, setValue] = useValidatedControlField(name);
	const [isFocused, setIsFocused] = useState(false);
	const [showPassword, setShowPassword] = useState(
		isPassword ? false : undefined,
	);
	// shrink logic to avoid showing the label on top when value is changed programmatically
	const shrink = !!((value && value !== "") || isFocused);
	const { errorTranslation } = useZodErrorWithParameters(error);
	const hasError = !!error;
	const hasWarning = !!warningText && !hasError;
	const color = getColor(hasError, hasWarning);
	const helperText = getHelperText(
		errorTranslation,
		hideErrorText,
		warningText,
	);

	const { t } = useTranslation();
	return (
		<TextField
			onFocus={() => {
				setIsFocused(true);
			}}
			onBlur={() => {
				setIsFocused(false);
			}}
			label={t(labelTranslationKey)}
			name={name}
			error={hasError}
			color={color}
			helperText={helperText}
			variant="filled"
			type={isPassword ? (showPassword ? "text" : "password") : type}
			value={value}
			onChange={(e) => {
				setValue(capitalize ? e.target.value.toUpperCase() : e.target.value);
			}}
			sx={{
				cursor: isReadOnly ? "not-allowed" : "auto",
			}}
			{...props}
			inputProps={{
				pattern: type === "number" ? "[0-9]*" : undefined,
				...getInputProps(),
				defaultValue: undefined, // MUI complains about having defaultValue and value set in input
				...props.inputProps,
			}}
			InputProps={{
				inputMode: type ? inputMode[type] : undefined,
				readOnly: isReadOnly,
				endAdornment: isPassword && (
					<InputAdornment position="end">
						<IconButton
							disabled={props.disabled ?? isReadOnly}
							aria-label={t("togglePasswordVisibility")}
							onClick={() => {
								setShowPassword((show) => {
									return !show;
								});
							}}
							onMouseDown={(event) => {
								event.preventDefault();
							}}
							edge="end"
						>
							{showPassword ? (
								<VisibilityOffOutlined />
							) : (
								<VisibilityOutlined />
							)}
						</IconButton>
					</InputAdornment>
				),
				...props.InputProps,
			}}
			InputLabelProps={{
				// avoid showing the label on top when value is changed externally
				shrink,
			}}
		/>
	);
};

export const inputMode: Partial<
	Record<
		HTMLInputTypeAttribute,
		React.HTMLAttributes<HTMLLIElement>["inputMode"]
	>
> = {
	number: "numeric",
	text: "text",
	email: "email",
};

export function getColor(hasError: boolean, hasWarning: boolean) {
	if (hasError) {
		return "error";
	}
	if (hasWarning) {
		return "warning";
	}
	return undefined;
}

function getHelperText(
	errorText: string | undefined,
	hideErrorText: boolean | undefined,
	warningText: string | undefined,
) {
	if (errorText && !hideErrorText) {
		return errorText;
	}
	if (warningText) {
		return warningText;
	}
	return undefined;
}
